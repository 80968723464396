import React, { useState, useContext } from 'react';
import { navigate } from 'gatsby';
import Helmet from 'react-helmet';
import { STIcon } from 'components/Brand/Icons/STIcon';
import { withFormik } from 'formik';
import { saveForm, init } from 'services/marketoService';
import * as Yup from 'yup';
import { calculateROI } from 'components/Tools/ROICalculator/calculations';
import { convertToolResultToParams } from 'utils/urlHelper';
import TextField from 'components/Core/TextField';
import Select from 'components/Core/Select';
import FormLabelWithField from 'components/Core/FormLabelWithField';
import Button from 'components/Core/Button';
import useIndustries from 'hooks/useIndustries';
import { getFormValid, fixedSubmitForm } from 'utils/formUtils';
import SweetScrollContext from 'utils/SweetScrollContext';
import { Revenue, CallPercentage, SoldPercentage } from '../utils/content';
import { ROIFormContainer } from '../styles';
import { FormContainer } from '../../styles';
import EmbedForm from '../../EmbeddableForm';
import EmbedLink from '../../EmbedLink';

const onSubmit = async (yupValues, marketoFormId, isEmbed) => {
  init(marketoFormId);
  const origin = typeof window !== 'undefined' ? window.location.origin : '';
  const values = [
    yupValues.company,
    yupValues.techs,
    yupValues.officeStaff,
    yupValues.revenue,
    yupValues.calls,
    yupValues.estimates,
    yupValues.leads,
    yupValues.jobs,
    yupValues.industry,
    yupValues.otherIndustry,
    yupValues.existingCustomer,
  ];

  const resultUrl = `/tools/roi-calculator-results?${convertToolResultToParams(values)}`;
  const results = calculateROI(values);

  dataLayer.push({
    event: 'calculator',
    calculator_type: 'ROI Calculator - entries',
    company: yupValues.company,
    no_of_technicians: yupValues.techs,
    average_ticket_size: yupValues.revenue,
    average_calls_per_day: yupValues.calls,
    booked_calls_percentage: `${yupValues.leads}%`,
    leads_to_estimates_percentage: `${yupValues.estimates}%`,
    sold_jobs_percentage: `${yupValues.jobs}%`,
    no_of_office_staff: yupValues.officeStaff,
    email: yupValues.email,
    industry: yupValues.industry,
    other_industry: yupValues.therIndustry,
    existing_ST_user: yupValues.existingCustomer,
    roiCalcResultLink: resultUrl, // latestResultsLink is not the right variable. Need to guess what it is.
  });

  await saveForm({
    ...values,
    email: yupValues.email,
    roiCalcWithST: results[0].value,
    roiCalcMonthlyRevenue: results[1].value,
    roiCalcAnnualRevenue: results[2].value,
    roiCalcResultLink: origin + resultUrl,
    marketoFormId,
  });

  isEmbed
    ? navigate('/tools/roi-calculator-results', {
        state: { params: convertToolResultToParams(values) },
      })
    : navigate(resultUrl);
};

const ROIForm = ({ width, height, isEmbed, marketoFormId, ...otherProps }) => {
  const { industryList, otherIndustryList } = useIndustries();
  const {
    values,
    setFieldValue,
    setFieldTouched,
    submitForm,
    validateForm,
    resetForm,
    submitCount,
    errors,
    touched,
  } = otherProps;
  const [submitStatus, setSubmitStatus] = useState(0); // 0: Normal, 1: Submitting, 2: Submit Success
  const formValid = getFormValid({ errors, submitCount });
  const { scrollToAnchor } = useContext(SweetScrollContext);

  const submit = () => {
    setSubmitStatus(1);
    fixedSubmitForm({ submitForm, validateForm })
      .then(() => {
        onSubmit(values);
        resetForm();
      })
      .catch(() => {
        setSubmitStatus(0);
        if (document) {
          scrollToAnchor('#roi-form');
        }
      });
  };

  return (
    <FormContainer id="roi-form" isEmbed={isEmbed} {...otherProps}>
      <div className="section-tool-page-form--wrapper">
        <div className="section-tool-page-form--container">
          <ROIFormContainer width={width} height={height} {...otherProps}>
            <p className="roi-form--description">* Required information</p>
            <div className="roi-form--form" data-position="roi form">
              <FormLabelWithField
                formControlProps={{ className: 'roi-form--form-item' }}
                label="Company name"
                isRequired
                helperText="Please input company name"
              >
                <TextField
                  id="company"
                  formValid={formValid}
                  handleChange={(id, value) => setFieldValue(id, value)}
                  handleBlur={(id) => setFieldTouched(id)}
                  values={values}
                  showOnlyFormError
                />
              </FormLabelWithField>

              <FormLabelWithField
                formControlProps={{ className: 'roi-form--form-item' }}
                label="What industry do you do the most business in?"
                isRequired
                helperText="Indicate what industry you do the majority of your business in. (eg HVAC, Plumbing, Electrical, etc)."
              >
                <Select
                  id="industry"
                  formValid={formValid}
                  placeholder="Select Industry"
                  options={industryList}
                  handleChange={(id, value) => setFieldValue(id, value)}
                  handleBlur={(id) => setFieldTouched(id)}
                  values={values}
                />
              </FormLabelWithField>
              {values.industry === 'Other' && (
                <FormLabelWithField>
                  <Select
                    id="otherIndustry"
                    placeholder="Select from other Industries"
                    options={otherIndustryList}
                    handleChange={(id, value) => setFieldValue(id, value)}
                    handleBlur={(id) => setFieldTouched(id)}
                    values={values}
                  />
                </FormLabelWithField>
              )}

              <FormLabelWithField
                formControlProps={{ className: 'roi-form--form-item' }}
                label="Number of techs"
                isRequired
                helperText="How many full-time technicians (e.g. plumbers, or electricians) work at your company? For best results, omit apprentices and helpers."
              >
                <TextField
                  id="techs"
                  type="number"
                  formValid={formValid}
                  handleChange={(id, value) => setFieldValue(id, value)}
                  handleBlur={(id) => setFieldTouched(id)}
                  values={values}
                  showOnlyFormError
                />
              </FormLabelWithField>

              <FormLabelWithField
                formControlProps={{ className: 'roi-form--form-item' }}
                label="Number of office staff"
                isRequired
                helperText="How many full-time office employees (eg. manager, dispatchers, CSRs, etc) work at your company?"
              >
                <TextField
                  id="officeStaff"
                  type="number"
                  formValid={formValid}
                  handleChange={(id, value) => setFieldValue(id, value)}
                  handleBlur={(id) => setFieldTouched(id)}
                  values={values}
                  showOnlyFormError
                />
              </FormLabelWithField>

              <FormLabelWithField
                formControlProps={{ className: 'roi-form--form-item' }}
                label="Average ticket"
                isRequired
                helperText="Please select average ticket"
              >
                <Select
                  id="revenue"
                  placeholder="Select amount ($)"
                  formValid={formValid}
                  handleChange={(id, value) => setFieldValue(id, value)}
                  handleBlur={(id) => setFieldTouched(id)}
                  values={values}
                  options={Revenue}
                />
              </FormLabelWithField>

              <FormLabelWithField
                formControlProps={{ className: 'roi-form--form-item' }}
                label="Average number of calls per day"
                isRequired
                helperText="Please input average number of calls per day"
              >
                <TextField
                  type="number"
                  id="calls"
                  formValid={formValid}
                  handleChange={(id, value) => setFieldValue(id, value)}
                  handleBlur={(id) => setFieldTouched(id)}
                  values={values}
                  showOnlyFormError
                />
              </FormLabelWithField>

              <FormLabelWithField
                formControlProps={{ className: 'roi-form--form-item' }}
                label="What percentage of calls are booked?"
                isRequired
                helperText="Please select percentage of calls are booked"
              >
                <Select
                  id="leads"
                  placeholder="Select percentage (%)"
                  formValid={formValid}
                  handleChange={(id, value) => setFieldValue(id, value)}
                  handleBlur={(id) => setFieldTouched(id)}
                  values={values}
                  options={CallPercentage}
                />
              </FormLabelWithField>

              <FormLabelWithField
                formControlProps={{ className: 'roi-form--form-item' }}
                label="What percentage of those leads turn into estimates?"
                isRequired
                helperText="Please input percentage of those leads turn into estimates"
              >
                <Select
                  id="estimates"
                  placeholder="Select percentage (%)"
                  formValid={formValid}
                  handleChange={(id, value) => setFieldValue(id, value)}
                  handleBlur={(id) => setFieldTouched(id)}
                  values={values}
                  options={CallPercentage}
                />
              </FormLabelWithField>

              <FormLabelWithField
                formControlProps={{ className: 'roi-form--form-item' }}
                label="What percentage of those estimates turn into sold jobs?"
                isRequired
                helperText="Please select percentage of those estimates turn into sold jobs"
              >
                <Select
                  id="jobs"
                  dropdownClassName="jobs_select"
                  placeholder="Select percentage (%)"
                  formValid={formValid}
                  handleChange={(id, value) => setFieldValue(id, value)}
                  handleBlur={(id) => setFieldTouched(id)}
                  values={values}
                  options={SoldPercentage}
                />
              </FormLabelWithField>

              <FormLabelWithField
                formControlProps={{ className: 'roi-form--form-item' }}
                label="Enter Business Email Address"
                isRequired
                helperText="Please provide your business email"
              >
                <TextField
                  id="email"
                  handleChange={(id, value) => setFieldValue(id, value)}
                  handleBlur={(id) => setFieldTouched(id)}
                  values={values}
                  formValid={formValid}
                  showOnlyFormError
                />
              </FormLabelWithField>

              <FormLabelWithField
                formControlProps={{ className: 'roi-form--form-item' }}
                label="Are you an existing ServiceTitan Customer?"
                isRequired
                helperText="Please let us know if you or your company is currently using ServiceTitan."
              >
                <Select
                  id="existingCustomer"
                  placeholder="Yes or No"
                  handleChange={(id, value) => setFieldValue(id, value)}
                  handleBlur={(id) => setFieldTouched(id)}
                  values={values}
                  formValid={formValid}
                  options={['Yes', 'No'].map((x) => ({ label: x, value: x }))}
                />
              </FormLabelWithField>

              <Button
                data-position="ROI Calculator - Submit CTA"
                className="roi-form--submit-button"
                type="primary"
                shadow={false}
                width="100%"
                onClick={() => submit()}
                disabled={submitStatus === 1}
              >
                Calculate ROI
                {submitStatus === 1 && <STIcon type="icon-loading" style={{ marginLeft: 5 }} />}
              </Button>

              <EmbedLink
                show={!isEmbed}
                openCopyEmbedLinkDialog={otherProps?.openCopyEmbedLinkDialog}
                pathUrl={otherProps.pathUrl}
              />
            </div>
          </ROIFormContainer>
        </div>
      </div>
      <Helmet>
        <script src="//app-sj26.marketo.com/js/forms2/js/forms2.min.js" async="true" />
      </Helmet>
    </FormContainer>
  );
};

const vSchema = Yup.object().shape({
  company: Yup.string().required('Company is required'),
  techs: Yup.number().integer().positive().required('Number of Techs is required'),
  officeStaff: Yup.number().integer().positive().required('Number of Office Staff is required'),
  revenue: Yup.string().required('Average ticket is required'),
  calls: Yup.number().integer().positive().required('Average # of calls is required'),
  estimates: Yup.string().required('Leads to estimate percentage is required'),
  leads: Yup.string().required('Percentage of calls is required'),
  jobs: Yup.string().required('Sold jobs percentage is required'),
  industry: Yup.string().required('Industry is required'),
  otherIndustry: Yup.string(),
  existingCustomer: Yup.string().required('Required'),
  email: Yup.string().email().required('Email is required'),
});

export const ROICalcForm = EmbedForm(
  withFormik({
    validationSchema: vSchema,
    handleSubmit: (values, { props }) => {
      onSubmit(values, props.marketoFormId, props.isEmbed);
    },
  })(ROIForm),
);
