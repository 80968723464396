import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SectionSideBySide from 'components/Sections/SectionSideBySide';
import MarketoForm from 'components/Form/MarketoForm';
import Text from 'components/Core/Text';
import useDefaultDemoForm from 'hooks/useDefaultDemoForm';

const DemoFormSection = (props) => {
  const { title, description, backgroundColor, formTitle, redirectUrl } = props;
  const inlineMarketingForm = props.inlineMarketingForm || useDefaultDemoForm();

  return (
    <DemoFormSectionContainer
      backgroundColor={backgroundColor}
      textSide={
        <div className="demo-form--text-container">
          <Text type="h2" color="white">
            {title}
          </Text>
          <Text type="p" color="white" fontSize="normal">
            {description}
          </Text>
        </div>
      }
      videoSide={
        <MarketoForm
          dataPosition="Conversion Panel"
          title={formTitle}
          inlineMarketingForm={inlineMarketingForm}
          redirectUrl={redirectUrl}
          backgroundColor="var(--white)"
        />
      }
      reversed={false}
    />
  );
};

DemoFormSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundColor: PropTypes.string,
  formTitle: PropTypes.string,
  redirectUrl: PropTypes.string,
};

const DemoFormSectionContainer = styled(SectionSideBySide)`
  background-color: ${(props) => props.backgroundColor};
  @media (max-width: 575px) {
    .demo-form--text-container {
      margin-top: 20px;
      margin-bottom: 32px;
    }
  }
`;

export default DemoFormSection;
