import React, { useState } from 'react';
import Modal from 'components/Core/Modal';
import Button from 'components/Core/Button';
import { EmbedLinkModalContainer } from '../styles.js';
import copy from 'clipboard-copy';

const EmbedLinkModal = ({ isModalOpen, onModalCancel, closable = true, pathUrl }) => {
  const [isCopied, setCopied] = useState(false);
  const formUrl = pathUrl?.pathname.split('/').pop();
  const embedLink = `<iframe src="${pathUrl?.origin}/tools/embedded-form?form_type=${formUrl}" width="700px" height="500px" name="iframe_servicetitan_form" title="ServiceTitan Embedded Form"></iframe>`;

  const handleCopy = () => {
    copy(embedLink);
    setCopied(true);
  };

  const handleClose = () => {
    onModalCancel();
    setCopied(false);
  };

  return (
    <Modal open={isModalOpen} handleClose={handleClose} width="520px" onBackdropClick={handleClose}>
      <EmbedLinkModalContainer>
        <h3 className="tools-embed-link--title">Embed on your website</h3>
        <p className="tools-embed-link--description">
          Copy the code below and add it to your website HTML
        </p>
        <p className="tools-embed-link--iframe-code">{embedLink}</p>
        <div className="tools-embed-link--button-group">
          <Button type={'white'} shadow={false} onClick={handleCopy} disabled={isCopied}>
            {isCopied ? 'Copied' : 'Copy'}
          </Button>
          <Button type={'secondary'} shadow={false} onClick={handleClose}>
            Close
          </Button>
        </div>
      </EmbedLinkModalContainer>
    </Modal>
  );
};

export default EmbedLinkModal;
