import React from 'react';
import { graphql } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import Hero from 'components/Tools/Hero';
import { ROICalcForm } from 'components/Tools/ROICalculator/Form';
import DescriptionBlock from 'components/Tools/DescriptionBlock';
import STTestimonialCarousel from 'components/Sections/TestimonialCarousel/STTestimonialCarousel';
import DemoFormSection from 'components/Sections/DemoFormSection';
import BookmarkContext from 'utils/BookmarkContext';
import { createToolsBreadcrumbs } from './utils/createToolsBreadcrumbs';

const ROICalculatorPage = ({ title = 'ROI Calculator', marketoFormId = 1322, ...otherProps }) => {
  const { contentfulTool: data, contentfulImage: photoImg } = otherProps.data || {};
  const pathUrl = otherProps.location;

  const allFeedbacks = [
    {
      title:
        '“ServiceTitan grew Bryant Electric’s revenue by 109%. It’s changed our business and it’s changed our life”',
      description: {
        description: '',
      },
      photo: photoImg.image,
      clientName: 'Jeff Bryant of Bryant Electric',
      companyName: 'ServiceTitan customer for 3 years',
    },
  ];

  const breadcrumbs = createToolsBreadcrumbs(title);
  const bookmarkData = {
    title: data.internalName,
    image: data?.cardImage?.image,
    subTitle: 'Tool',
    url: otherProps.path,
    type: 'tool',
  };

  return (
    <BookmarkContext.Provider value={bookmarkData}>
      <ToolboxLayout
        breadcrumbs={breadcrumbs}
        contentfulSeo={data.seo}
        layout="toolbox"
        {...otherProps}
      >
        <Hero data={data.hero} />
        <ROICalcForm pathUrl={pathUrl} marketoFormId={marketoFormId} />
        {data.description && <DescriptionBlock data={data.description} />}
        <STTestimonialCarousel
          type="descriptiononly"
          background="var(--skyline-1)"
          feedbacks={allFeedbacks}
        />
        <DemoFormSection
          backgroundColor="var(--dark)"
          formTitle="Let’s get started."
          description="Explore why 100,000+ contractors trust Service Titan to run their business."
          title="Book a personalized demo today."
        />
      </ToolboxLayout>
    </BookmarkContext.Provider>
  );
};

export default ROICalculatorPage;

export const ROICalculatorPageQuery = graphql`
  query ROICalculatorPageQuery {
    contentfulTool(internalName: { eq: "ROI Calculator" }) {
      ...Tool
    }
    contentfulImage(contentful_id: { eq: "1K4u6yyVbtkWXnOoSVfc5Q" }) {
      internalName
      image {
        file {
          url
        }
      }
    }
  }
`;
