import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';
import { Col, Row } from 'components/Core/Grid';

const SectionSideBySide = ({
  backgroundColor,
  backgroundImageTypeWithPosition,
  reversed,
  textSide,
  videoSide,
  ...otherProps
}) => {
  return (
    <SectionLayout
      backgroundColor={backgroundColor}
      backgroundImageTypeWithPosition={backgroundImageTypeWithPosition}
      {...otherProps}
    >
      <SectionRow type="flex" justify="center" align="middle" gutter={80}>
        <SectionCol lg={12} md={12} sm={12} xs={24}>
          {reversed ? videoSide : textSide}
        </SectionCol>
        <SectionCol lg={12} md={12} sm={12} xs={24}>
          {reversed ? textSide : videoSide}
        </SectionCol>
      </SectionRow>
    </SectionLayout>
  );
};

SectionSideBySide.propTypes = {
  textSide: PropTypes.object.isRequired, // component
  videoSide: PropTypes.object.isRequired, // component
  reversed: PropTypes.bool, // if false: right: text component, left: Video or Image Component
  backgroundImg: PropTypes.string, // background image
  backgroundColor: PropTypes.string,
  backgroundImageTypeWithPosition: PropTypes.string,
};

SectionSideBySide.defaultProps = {
  reversed: false,
};

const SectionRow = styled(Row)`
  margin-left: 0px !important;
  margin-right: 0px !important;
`;

const SectionCol = styled(Col)`
  align-items: center;
  display: flex;

  @media (max-width: 991px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  @media (max-width: 575px) {
    padding-bottom: 20px;
    width: 100%;
  }
`;

export default SectionSideBySide;
