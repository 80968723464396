import React, { useState } from 'react';
import EmbedLinkModal from '../EmbedLinkModal';

function EmbedForm(WrappedComponent) {
  const EmbeddableForm = ({ ...props }) => {
    const [openCopyEmbedLinkDialog, setOpenCopyEmbedLinkDialog] = useState(false);

    return (
      <>
        <WrappedComponent {...props} openCopyEmbedLinkDialog={setOpenCopyEmbedLinkDialog} />

        {!props.isEmbed && (
          <EmbedLinkModal
            isModalOpen={openCopyEmbedLinkDialog}
            onModalCancel={() => setOpenCopyEmbedLinkDialog(false)}
            pathUrl={props.pathUrl}
          />
        )}
      </>
    );
  };

  return EmbeddableForm;
}

export default EmbedForm;
